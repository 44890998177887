<template>
  <section class="container">
    <div class="contact"
         :class="{ 'padding-webview': checkWebview(), 'padding-has-caution': isHasCaution }">
      <h3 class="title">お問い合わせ</h3>

      <div class="contact__container">
        <!--content-->
        <router-view/>
      </div>
    </div>
  </section>
</template>

<script>
import Common from '@/mixins/common.mixin'
import { mapState } from 'vuex'

export default {
  name: 'Index',

  mixins: [Common],

  mounted () {
    // hide app's loading after page render
    if (Common.methods.checkWebview()) {
      Common.methods.triggerEventApp('PAGE_RENDERED', {})
    }
  },
  computed: {
    ...mapState('auth', ['profile']),
    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  }

}
</script>

<style lang="scss" scoped>
.container {
  @media #{$info-phone} {
    padding: 0;
  }
}
.contact {
  padding: 90px 0 96px;
  @media #{$info-phone} {
    padding: 85px 0 72px;
  }
  &.padding-has-caution {
    @media #{$info-phone} {
      padding-top: 120px;
    }
  }
  &.padding-webview {
    padding: 40px 0 0;
  }
  .title {
    font-family: $font-family-title;
    color: $default-green;
    @include font-size(36px);
    font-weight: 400;
    text-align: center;
    margin-bottom: 40px;
    @media #{$info-phone} {
      font-size: 32px;
      margin-bottom: 30px;
    }
  }
  &__container {
    border: 6px solid #E7E5DE;
    border-radius: 6px;
    background-color: #FAFAFA;
    @media #{$info-phone} {
      border: 3px solid #E7E5DE;
      border-left: none;
      border-right: none;
      border-radius: 0;
    }
  }
}
</style>
